import { getValidCollectionId, undoable, withBi } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentConnection, Plugin } from '../../../constants/api-types'
import CoreApi from '../core-api'
import { SecondsToResetDefaults, SuccessActionTypes } from '../../../constants/success-settings'
import { ROLE_DOWNLOAD_MESSAGE, ROLE_FORM, ROLE_MESSAGE, ROLE_SUBMIT_BUTTON } from '../../../constants/roles'
import { getExtraMessageText } from '../services/form-service'
import { LinkTypes, mediaTypes } from '../../../panels/settings-panel/constants'
import { EMPTY_EMAIL_ID, innerText, isEmptyEmailId } from '../../../utils/utils'
import * as _ from 'lodash'
import {
  DEFAULT_EXTERNAL_LINK_OBJECT,
  DEFAULT_LINK_OBJECT,
  DEFAULT_UPLOAD_OBJECT,
  LinkPanelTypesToActionTypes,
  MediaManagerUploadedObjectResponse,
  UploadStatuses,
  VISIBLE_LINK_PANEL_SECTIONS,
  VISIBLE_LINK_PANEL_SECTIONS_ADI,
} from './consts/links-settings'
import RemoteApi from '../../../panels/commons/remote-api'
import { PremiumRestriction } from '../../../constants/premium'
import { MissingField } from '../../../panels/settings-panel/components/crucial-elements/crucial-elements'
import {
  convertPluginsToFormsPlugins,
  findPlugin,
  getPlugins,
  isNativeForm,
  removePlugin,
  updatePlugin,
} from '../plugins/utils'
import { OwnSettingsPanelProps } from '../../../panels/form-settings-panel/components/form-settings-panel'
import translations from '../../../utils/translations'
import { PAYMENT_STATUS } from '../../../panels/form-settings-panel/components/payment/constants'
import { TABS } from '../../../panels/form-settings-panel/constants'
import { FormPlugin } from '../../../constants/plugins'
import Experiments from '@wix/wix-experiments'
import {
  ALWAYS_DISPLAY_MESSAGE,
  MessageDisplayOption,
} from "../../../panels/form-settings-panel/components/submit-message/constants";

export interface InitialSettingsPanelData {
  message?: string
  links?: any
  email?: string
  secondEmail?: string
  missingFields?: MissingField[]
  otherFormsNames?: string[]
  isCollectionExists?: boolean
  isRegistrationForm?: boolean
  restrictions?: PremiumRestriction
  productName?: string
  productPrice?: string
}

export interface InitialSubmitPanelData {
  message?: string
  links?: any
  missingFields?: MissingField[]
  isRegistrationForm?: boolean
  restrictions?: PremiumRestriction
}

interface GetEmailsResponse {
  email: {
    email: string
    emailId: string
  }
  secondEmail: {
    email: string
    emailId: string
  }
}

export const SETTINGS_API_NAME = 'settings'

const apiPath = funcName => `${SETTINGS_API_NAME}.${funcName}`

export default class SettingsApi {
  private biLogger: any
  private experiments: Experiments
  private boundEditorSDK: any
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private editorSDK: any

  constructor(boundEditorSDK, editorSDK, coreApi: CoreApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.editorSDK = editorSDK
    this.experiments = experiments
  }

  public async loadInitialSubmitPanelData(
    componentRef: ComponentRef,
  ): Promise<InitialSubmitPanelData> {
    const formComponentRef = await this.coreApi.findComponentByRole(componentRef, ROLE_FORM)
    const formComponentConnection = await this.coreApi.getComponentConnection(formComponentRef)
    const { restrictions } = await this.coreApi.premium.getPremiumRestrictions()
    return Promise.all([
      this.getMessage(formComponentRef),
      this.getMessage(formComponentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getSubmitOptionsData(formComponentRef, formComponentConnection),
      this.getCrucialElements(formComponentRef, formComponentConnection),
      this.coreApi.isRegistrationForm(formComponentRef),
      this.coreApi.isMultiStepForm(formComponentRef),
      this.coreApi.getButtonLabel(componentRef),
      this.determinePaymentStatus(formComponentRef, restrictions),
    ]).then(
      ([
        successMessage,
        downloadMessage,
        links,
        missingFields,
        isRegistrationForm,
        isMultiStepForm,
        buttonLabel,
        { paymentStatus },
      ]) => ({
        successMessage: successMessage.text,
        downloadMessage: downloadMessage.text,
        messagePosition: successMessage.position || downloadMessage.position,
        links,
        missingFields,
        isRegistrationForm,
        isMultiStepForm,
        restrictions,
        buttonLabel,
        formComponentRef,
        formComponentConnection,
        submitComponentRef: componentRef,
        paymentStatus,
      }),
    )
  }

  public async loadInitialPanelData(
    componentRef: ComponentRef,
    componentConnection: ComponentConnection,
  ): Promise<InitialSettingsPanelData> {
    return Promise.all([
      this.getMessage(componentRef),
      this.getMessage(componentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getSubmitOptionsData(componentRef, componentConnection),
      this.getEmails(componentRef, componentConnection),
      this.getCrucialElements(componentRef, componentConnection),
      this.getOtherFormsNames(componentRef),
      this.coreApi.isCollectionExists(componentRef, componentConnection),
      this.coreApi.isRegistrationForm(componentRef),
      this.coreApi.isMultiStepForm(componentRef),
      this.coreApi.premium.getPremiumRestrictions(),
      this.getSubmitComponentRef(componentRef),
      this.coreApi.getOwnerEmail(),
    ]).then(
      ([
        successMessage,
        downloadMessage,
        links,
        { email, secondEmail },
        missingFields,
        otherFormsNames,
        isCollectionExists,
        isRegistrationForm,
        isMultiStepForm,
        { restrictions },
        submitComponentRef,
        ownerEmailObj,
      ]) => {
        const ownerEmail = _.get(ownerEmailObj, 'email', '')
        const firstEmail = _.get(email, 'email', ownerEmail)

        return {
          successMessage: successMessage.text,
          downloadMessage: downloadMessage.text,
          messagePosition: successMessage.position || downloadMessage.position,
          links,
          email: firstEmail,
          secondEmail: _.get(secondEmail, 'email', ''),
          missingFields,
          otherFormsNames,
          isCollectionExists,
          isRegistrationForm,
          isMultiStepForm,
          restrictions,
          plugins: convertPluginsToFormsPlugins(getPlugins(componentConnection)),
          formComponentRef: componentRef,
          formComponentConnection: componentConnection,
          submitComponentRef,
          ownerEmail,
        }
      },
    )
  }

  public async loadInitialSettingsPanelData({
    componentRef,
    componentConnection,
    displayedTab,
  }: {
    componentRef: ComponentRef
    componentConnection: ComponentConnection
    displayedTab: any
  }): Promise<Partial<OwnSettingsPanelProps>> {
    const { restrictions } = await this.coreApi.premium.getPremiumRestrictions()
    return Promise.all([
      this.getOtherFormsNames(componentRef),
      this.getMessage(componentRef),
      this.getMessage(componentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getSubmitOptionsData(componentRef, componentConnection),
      this.getEmails(componentRef, componentConnection),
      this.coreApi.getOwnerEmail(),
      this.getCrucialElements(componentRef, componentConnection),
      this.getSubmitComponentRef(componentRef),
      this.coreApi.isCollectionExists(componentRef, componentConnection),
      this.getLabels(), // TODO: Move all secondary calls to componentWillMount on form-settings component
      this.coreApi.isWixChatInstalled(),
      this.boundEditorSDK.info.getCurrency(),
      this.determinePaymentStatus(componentRef, restrictions),
      this.boundEditorSDK.environment.getLocale(),
    ]).then(
      ([
        otherFormsNames,
        successMessage,
        downloadMessage,
        links,
        { email, secondEmail },
        ownerEmailObj,
        missingFields,
        submitComponentRef,
        isCollectionExists,
        labels,
        isWixChatInstalled,
        currency,
        { paymentStatus, paymentStatusChanged },
        locale,
      ]) => {
        const ownerEmail = _.get(ownerEmailObj, 'email', '')
        const emailIdFromConfig = _.get(componentConnection, 'config.emailId')
        const emailIdFromServer = _.get(email, 'email', ownerEmail) || ownerEmail
        const firstEmail = emailIdFromConfig === EMPTY_EMAIL_ID ? '' : emailIdFromServer
        const plugins = getPlugins(componentConnection)
        const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
        const items = _.get(paymentPlugin, 'payload.items')
        const products = _.map(items, (item, id) => ({ id, ...item }))
        const isRegistrationForm = !!findPlugin(plugins, FormPlugin.REGISTRATION_FORM)
        const isMultiStepForm = !!findPlugin(plugins, FormPlugin.MULTI_STEP_FORM)
        const preset = _.get(componentConnection, 'config.preset')
        const formPresetType = _.get(componentConnection, 'config.presetType')

        const showPaymentTabInFormBuilderPlugin =
          (isNativeForm(plugins) || !!findPlugin(plugins, FormPlugin.PAYMENT_FORM))

        const showPaymentTabInGetSubscribersPlugin =
          this.experiments.enabled('specs.cx.FormBuilderShowPaymentTabInGetSubscribers') &&
          !!findPlugin(plugins, FormPlugin.GET_SUBSCRIBERS)

        const showPaymentTabInRegistrationFormPlugin =
          this.experiments.enabled('specs.cx.FormBuilderShowPaymentTabInRegistrationForm') &&
          !!findPlugin(plugins, FormPlugin.REGISTRATION_FORM)

        const showPaymentTab =
          showPaymentTabInFormBuilderPlugin ||
          showPaymentTabInGetSubscribersPlugin ||
          showPaymentTabInRegistrationFormPlugin

        const showEmailMarketingTab = !!findPlugin(plugins, FormPlugin.GET_SUBSCRIBERS)

        const secondsToResetForm = componentConnection.config.secondsToResetForm

        const settingsPanelProps: Partial<OwnSettingsPanelProps> = {
          isCollectionExists,
          preset,
          formPresetType,
          plugins: convertPluginsToFormsPlugins(plugins),
          email: firstEmail,
          secondEmail: _.get(secondEmail, 'email', ''),
          labels,
          formLabelId: componentConnection.config.formLabelId,
          selectedLabels: componentConnection.config.labels || [],
          formName: componentConnection.config.formName,
          lastValidFormName: componentConnection.config.formName,
          otherFormsNames,
          successActionType: componentConnection.config.successActionType || SuccessActionTypes.SHOW_MESSAGE,
          secondsToResetForm: componentConnection.config.secondsToResetForm || SecondsToResetDefaults.MIN,
          messageDisplayOption: _.eq(secondsToResetForm, ALWAYS_DISPLAY_MESSAGE) ? MessageDisplayOption.ALWAYS : MessageDisplayOption.CUSTOM_TIME,
          links,
          missingFields,
          successMessage:
            _.get(successMessage, 'text') ||
            (isRegistrationForm
              ? translations.t('settings.errorMessage.registrationForm')
              : translations.t('settings.successMessage.default')),
          downloadMessage:
            _.get(downloadMessage, 'text') || translations.t('settings.successMessage.download'),
          restrictions,
          formComponentRef: componentRef,
          submitComponentRef: submitComponentRef,
          messagePosition: successMessage.position || downloadMessage.position,
          isWixChatInstalled,
          productId: _.get(products, '[0].id'),
          productName: _.get(products, '[0].name'),
          productPrice: _.get(products, '[0].price'),
          currency,
          paymentStatus: paymentStatus || PAYMENT_STATUS.GET_STARTED,
          paymentStatusChanged: !!paymentStatusChanged,
          selectedTab: displayedTab || TABS.MAIN,
          isRegistrationForm: isRegistrationForm,
          isMultiStepForm,
          showPaymentTab,
          isGetSubscribersForm: !!findPlugin(plugins, FormPlugin.GET_SUBSCRIBERS),
          locale,
          showEmailMarketingTab,
        }

        return settingsPanelProps
      },
    )
  }

  public async createCollectionAndOpenPopup(formComponentRef: ComponentRef, msid: string, extraBiData = {}) {
    const collectionId = await this.createCollection(formComponentRef, extraBiData)
    this.coreApi.managePanels.openPublishSitePopup(formComponentRef, msid)
    return collectionId
  }

  @undoable()
  public async createCollection(componentRef: ComponentRef, extraBiData = {}): Promise<string> {
    return this.coreApi.createCollection(componentRef, extraBiData)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public setComponentConnection(
    connectToRef: ComponentRef,
    connectionConfig,
    deepMerge = true,
    _biData = {},
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig, deepMerge)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setLabels(componentRef: ComponentRef, labels: string[], _biData = {}) {
    return this.coreApi.setComponentConnection(componentRef, { labels }, false)
  }

  public async saveProduct(componentRef: ComponentRef, currency: string, product: Product) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins: Plugin[] = _.get(componentConnection, 'config.plugins')

    let paymentPlugin: Plugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)

    const initPaymentPlugin = () => {
      paymentPlugin = {
        id: FormPlugin.PAYMENT_FORM,
        payload: {
          currency,
          items: {
            [product.id]: {
              name: product.name,
              price: product.price,
              quantity: product.quantity || 1,
            },
          },
        },
      }
    }

    const updatePaymentPlugin = () => {
      paymentPlugin.payload.currency = currency
      paymentPlugin.payload.items[product.id] = {
        name: product.name,
        price: product.price,
        quantity: product.quantity || 1,
      }
    }

    if (paymentPlugin && paymentPlugin.payload) {
      updatePaymentPlugin()
    } else {
      const collectionId = getValidCollectionId(
        componentRef.id,
        _.get(componentConnection, 'config.collectionId'),
      )
      if (collectionId) {
        await this.coreApi.collectionsApi.addPaymentField(collectionId)
      }
      initPaymentPlugin()
    }

    const updatedPlugins = updatePlugin(plugins, paymentPlugin)

    return this.setComponentConnection(
      componentRef,
      {
        plugins: updatedPlugins,
      },
      false,
    )
  }

  public async removePlugin(componentRef: ComponentRef, pluginId: FormPlugin) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins = getPlugins(componentConnection)

    return this.setComponentConnection(
      componentRef,
      {
        plugins: removePlugin(plugins, pluginId),
      },
      false,
    )
  }

  public async determinePaymentStatus(
    componentRef: ComponentRef,
    restrictions: PremiumRestriction
  ): Promise<{ paymentStatus: PAYMENT_STATUS; paymentStatusChanged: boolean }> {
    const [hasConnectedPayment, componentConnection] = await Promise.all([
      this.coreApi.hasConnectedPayment(),
      this.coreApi.getComponentConnection(componentRef),
    ])
    const plugins = getPlugins(componentConnection)
    const oldPaymentStatus =
      (await _.get(componentConnection.config, 'paymentStatus')) || PAYMENT_STATUS.GET_STARTED
    const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)

    const getPaymentStatus = () => {
      if (!paymentPlugin || !paymentPlugin.payload) {
        return PAYMENT_STATUS.GET_STARTED
      }

      if (!restrictions.allowedPlugins[FormPlugin.PAYMENT_FORM])
        return PAYMENT_STATUS.UPGRADE_TO_ASCEND

      return hasConnectedPayment ? PAYMENT_STATUS.CONNECTED : PAYMENT_STATUS.MISSING_PAYMENT_METHOD
    }

    const paymentStatus = getPaymentStatus()
    const paymentStatusChanged =
      paymentStatus !== oldPaymentStatus &&
      oldPaymentStatus !== PAYMENT_STATUS.MISSING_PAYMENT_METHOD

    await this.coreApi.setComponentConnection(componentRef, { paymentStatus })
    return { paymentStatus, paymentStatusChanged }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED })
  public async setSuccessActionTypeADI(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    showTitles,
    newSuccessMessage = '',
    _biData = {},
  ) {
    await this._setSuccessActionType(connectToRef, successActionType, successLinkValue, {
      newMessage: newSuccessMessage,
    })
    await this.coreApi.layout.updateFieldsLayoutADI(connectToRef, { showTitles })
  }

  private async _handleActionTypeChange(formComponentRef: ComponentRef, successActionType: SuccessActionTypes, oldSuccessActionType, newMessage: String) {
    const isMultistep = await this.coreApi.isMultiStepForm(formComponentRef)

    switch (oldSuccessActionType) {
      case SuccessActionTypes.SHOW_MESSAGE:
        await this._removeMessage(formComponentRef)
        if (isMultistep && successActionType !== SuccessActionTypes.DOWNLOAD_DOCUMENT) {
          await this.coreApi.steps.removeThankYouStep(formComponentRef)
        }
        break
      case SuccessActionTypes.DOWNLOAD_DOCUMENT:
        await this._removeMessage(formComponentRef, ROLE_DOWNLOAD_MESSAGE)
        if (isMultistep && successActionType !== SuccessActionTypes.SHOW_MESSAGE) {
          await this.coreApi.steps.removeThankYouStep(formComponentRef)
        }
        break
    }
    switch (successActionType) {
      case SuccessActionTypes.SHOW_MESSAGE:
        if (isMultistep && oldSuccessActionType !== SuccessActionTypes.DOWNLOAD_DOCUMENT) {
          await this.coreApi.steps.restoreThankYouStep(formComponentRef, newMessage)
        } else {
          await this.coreApi.fields.restoreHiddenMessage(formComponentRef, newMessage)
        }
        break
      case SuccessActionTypes.DOWNLOAD_DOCUMENT:
        if (isMultistep && oldSuccessActionType !== SuccessActionTypes.SHOW_MESSAGE) {
          await this.coreApi.steps.restoreThankYouStep(formComponentRef, newMessage, ROLE_DOWNLOAD_MESSAGE)
        } else {
          await this.coreApi.fields.restoreDownloadDocumentMessage(formComponentRef, newMessage)
        }
        break
    }
  }

  private async _setSuccessActionType(
    formComponentRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    { newMessage }: { newMessage?: string } = {}
  ) {
    const {
      config: { successActionType: oldSuccessActionType },
    } = await this.coreApi.getComponentConnection(formComponentRef)

    await this.coreApi.setComponentConnection(
      formComponentRef,
      { successActionType, successLinkValue },
      false,
    )

    await this._handleActionTypeChange(formComponentRef, successActionType, oldSuccessActionType, newMessage)
  }

  @undoable()
  public async setSuccessActionType(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    { newMessage }: { newMessage?: string } = {}
  ) {
    return this._setSuccessActionType(connectToRef, successActionType, successLinkValue, {
      newMessage,
    })
  }

  private async _removeMessage(componentRef: ComponentRef, role: string = ROLE_MESSAGE) {
    const get = async () => {
      const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
      const messageLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: messageRef,
      })
      return { messageRef, messageLayout }
    }
    const { messageRef, messageLayout } = await get()
    if (!messageLayout) {
      return
    }

    return this.coreApi.removeComponentRef(messageRef)
  }

  @undoable()
  public async handleSuccessLinkPanel(componentRef: ComponentRef, isADI = false) {
    const {
      config: { successLinkValue, successActionType },
    } = await this.coreApi.getComponentConnection(componentRef)

    let linkObject
    try {
      linkObject = await this.boundEditorSDK.editor.openLinkPanel({
        value: successLinkValue,
        visibleSections: isADI ? VISIBLE_LINK_PANEL_SECTIONS_ADI : VISIBLE_LINK_PANEL_SECTIONS,
      })
    } catch (e) {
      return {
        chosenLinkType: null,
        successLinkText: null,
        linkObject: null,
      }
    }

    const successLinkText = await this.boundEditorSDK.editor.utils.getLinkAsString({
      link: linkObject,
    })

    const chosenLinkType =
      linkObject && linkObject.type
        ? LinkPanelTypesToActionTypes[linkObject.type]
        : successActionType
    await this.setSuccessActionType(componentRef, chosenLinkType, linkObject)

    const successLinkType = await this._getLinkType(linkObject)
    const successLinkSubType = this._getLinkSubType(successLinkText, successLinkType, linkObject)

    return {
      chosenLinkType: chosenLinkType,
      successLinkText,
      linkObject,
      successLinkType,
      successLinkSubType,
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.CLICK_UPLOAD_BUTTON })
  public async updateDownloadSection(componentRef: ComponentRef, _biData) {
    let {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)

    try {
      const uploadedObject: Array<MediaManagerUploadedObjectResponse> = await this.boundEditorSDK.editor.openMediaPanel({
        mediaType: mediaTypes.DOCUMENT,
        isMultiSelect: false,
      })

      if (uploadedObject) {
        successLinkValue.docId = _.head(uploadedObject).uri
        successLinkValue.name = _.head(uploadedObject).title
        successLinkValue.status = UploadStatuses.UPLOAD_SUCCESS
      }
    } catch (error) {
      successLinkValue.status = UploadStatuses.UPLOAD_FAILED
    }
    await this.setComponentConnection(
      componentRef,
      {
        successLinkValue: successLinkValue,
      },
      false,
    )
    return successLinkValue
  }

  public async getSubmitOptionsData(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ) {
    const {
      config: { successActionType, successLinkValue },
    } = componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    const getText = linkObj => this.boundEditorSDK.editor.utils.getLinkAsString({ link: linkObj })

    const links = {
      [SuccessActionTypes.LINK]: {
        text: await getText(DEFAULT_LINK_OBJECT),
        object: DEFAULT_LINK_OBJECT,
      },
      [SuccessActionTypes.EXTERNAL_LINK]: {
        text: await getText(DEFAULT_EXTERNAL_LINK_OBJECT),
        object: DEFAULT_EXTERNAL_LINK_OBJECT,
      },
      [SuccessActionTypes.DOWNLOAD_DOCUMENT]: {
        object: DEFAULT_UPLOAD_OBJECT,
      },
    }

    if (successActionType !== SuccessActionTypes.SHOW_MESSAGE) {
      links[successActionType] = {
        text: await getText(successLinkValue),
        object: successLinkValue,
      }
    }
    return links
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setEmail(componentRef, emailIdKey, email, _biData = {}) {
    let emailId

    if (!_.isEmpty(email)) {
      const { id } = await this.remoteApi.insertEmail(email)
      emailId = id
    } else {
      emailId = EMPTY_EMAIL_ID
    }

    return this.setComponentConnection(componentRef, { [emailIdKey]: emailId })
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED,
    endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED,
  })
  public async updateMessage(
    componentRef: ComponentRef,
    { newMessage, role = ROLE_MESSAGE },
    _biData = {},
  ) {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
    const data = await this.boundEditorSDK.components.data.get({ componentRef: messageRef })

    return this.boundEditorSDK.components.data.update({
      componentRef: messageRef,
      data: getExtraMessageText({ data, newMessage }),
    })
  }

  public async getSubmitButtonLabel(componentRef: ComponentRef) {
    const submitButtonRef = await this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
    return this.coreApi.getButtonLabel(submitButtonRef)
  }

  public async getSubmitComponentRef(componentRef: ComponentRef) {
    const submitRef = await this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
    return submitRef
  }

  @undoable()
  public async updateSubmitButtonLabel(componentRef: ComponentRef, newLabel: string) {
    const submitButtonRef = await this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
    return this.coreApi.updateButtonLabel(submitButtonRef, newLabel)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.SECONDS_TO_RESET_UPDATED })
  public setComponentConnectionResetUpdated(
    connectToRef: ComponentRef,
    connectionConfig,
    _biData = {},
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getLabels() {
    return this.remoteApi.getLabels()
  }

  public async getMessage(
    componentRef: ComponentRef,
    role: string = ROLE_MESSAGE,
  ): Promise<Message> {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
    if (!messageRef) {
      return { text: '' }
    }
    const [{ data, layout }] = await this.boundEditorSDK.components.get({
      componentRefs: messageRef,
      properties: ['data', 'layout'],
    })
    const { x, y } = layout
    return { text: _.unescape(innerText(data.text)), position: { x, y } }
  }

  public async getEmails(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ): Promise<GetEmailsResponse> {
    const {
      config: { emailId, secondEmailId },
    } = componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    // both not empty - use single api call to fetch both
    if (!isEmptyEmailId(emailId) && !isEmptyEmailId(secondEmailId)) {
      return this.remoteApi.getEmailsById(emailId, secondEmailId)
    }

    // both empty - return empty valid object
    if (isEmptyEmailId(emailId) && isEmptyEmailId(secondEmailId)) {
      return {
        email: {
          emailId,
          email: '',
        },
        secondEmail: {
          emailId: secondEmailId,
          email: '',
        },
      }
    }

    // one of them not empty - use single api call to fetch email
    if (isEmptyEmailId(secondEmailId)) {
      return {
        email: await this.remoteApi.getEmailById(emailId),
        secondEmail: {
          emailId: secondEmailId,
          email: '',
        },
      }
    } else {
      return {
        email: {
          emailId,
          email: '',
        },
        secondEmail: await this.remoteApi.getEmailById(secondEmailId),
      }
    }
  }

  public async getCrucialElements(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ) {
    let connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    const { controllerRef, config } = connection

    const plugins = _.get(config, 'plugins')
    const successActionType = _.get(config, 'successActionType')

    const pluginApi = this.coreApi.plugins.withPlugins(plugins)

    if (pluginApi) {
      //TODO: Thing about different solution
      const funcName = 'getCrucialElements'

      if (pluginApi.supportApi(apiPath(funcName))) {
        return pluginApi.callApi(apiPath(funcName), componentRef, connection)
      }
    }

    // TODO: Merge this with above using the plugin system solution

    let isPreviousButtonMissingPromise = Promise.resolve(null)
    let isNextButtonMissingPromise = Promise.resolve(null)

    if (!!_.find(plugins, { id: FormPlugin.MULTI_STEP_FORM })) {
      isPreviousButtonMissingPromise = this.coreApi.steps.isPreviousButtonMissing(componentRef)
      isNextButtonMissingPromise = this.coreApi.steps.isNextButtonMissing(componentRef)
    }

    const isMessageFieldMissingPromise =
      successActionType === SuccessActionTypes.SHOW_MESSAGE
        ? this.coreApi.isFieldMissingByRole(componentRef, ROLE_MESSAGE)
        : Promise.resolve(null)
    const isDownloadMessageFieldMissingPromise =
      successActionType === SuccessActionTypes.DOWNLOAD_DOCUMENT
        ? this.coreApi.isFieldMissingByRole(componentRef, ROLE_DOWNLOAD_MESSAGE)
        : Promise.resolve(null)

    const missingFields = await Promise.all([
      isMessageFieldMissingPromise,
      isDownloadMessageFieldMissingPromise,
      this.coreApi.isFieldMissingByRole(componentRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.isEmailFieldMissing(controllerRef),
      isPreviousButtonMissingPromise,
      isNextButtonMissingPromise,
    ])

    return _.filter(missingFields)
  }

  public async getOtherFormsNames(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ): Promise<string[]> {
    const { controllerRef } =
    componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const controllers = _.map(
      await this.boundEditorSDK.controllers.listAllControllers(),
      ({ controllerRef }) => controllerRef,
    )
    return await Promise.all(
      _.map(_.pullAllBy(controllers, [controllerRef], 'id'), async formControllerRef => {
        const formRef = await this.coreApi.findConnectedComponent(formControllerRef, ROLE_FORM)
        if (!formRef) {
          return ''
        }
        const {
          config: { formName },
        } = await this.coreApi.getComponentConnection(formRef)
        return formName
      }),
    )
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async updateFormName(
    connectToRef: ComponentRef,
    { formName }: { formName: string },
    _biData = {},
  ) {
    await this.coreApi.setComponentConnection(connectToRef, { formName })
    const {
      config: { formLabelId, labels },
    } = await this.coreApi.getComponentConnection(connectToRef)
    if (formLabelId && _.includes(labels, formLabelId)) {
      await this.remoteApi.updateTag(formLabelId, formName)
    }
  }

  private async _getLinkType(linkObject) {
    if (!_.get(linkObject, 'pageId')) {
      if (_.get(linkObject, 'url')) {
        return LinkTypes.EXTERNAL_LINK
      }
      return LinkTypes.NONE
    }

    const linkedPageRef = { type: 'DESKTOP', id: linkObject.pageId.substring(1) }
    const linkData = await this.boundEditorSDK.components.data.get({
      componentRef: linkedPageRef,
    })
    return _.get(linkData, 'isPopup') ? LinkTypes.LIGHTBOX : LinkTypes.PAGE
  }

  private _getLinkSubType(successLinkText, successLinkType: LinkTypes, linkObject) {
    switch (successLinkType) {
      case LinkTypes.PAGE:
        return successLinkText
      case LinkTypes.LIGHTBOX:
        return linkObject.pageId
      case LinkTypes.EXTERNAL_LINK:
        return linkObject.url
      default:
        return null
    }
  }
}
